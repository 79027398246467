import React, { useEffect, useMemo, useState } from "react"
import "./HeroHeader.less"
import withOwnershipCheck from "./withOwnershipCheck"
import { getHeaderComponent, getTileComponent } from "../utils"
import AtsCarousel from "./AtsCarousel"
import { Button, Row } from "antd"
import PropTypes from "prop-types"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import withTileBadgeCheck from "./withTileBadgeCheck"
import { useTranslation } from "react-i18next"

const HeroHeader = ({
  dataSource,
  defaultIndex,
  index,
  onChange,
  showSlider = true,
  className = "",
}) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex)

  const { t } = useTranslation()

  useEffect(() => {
    setCurrentIndex(index)
  }, [index])

  const highlightTile = highlightIndex => {
    const allTiles = document.querySelectorAll(".hero-slider .ats-card")
    allTiles.forEach((item, index) => {
      if (index === highlightIndex) {
        item.classList.add("highlighted")
      } else {
        item.classList.remove("highlighted")
      }
    })
  }

  const focusedHeader = useMemo(() => {
    const focusedHeaderItem = dataSource[currentIndex ?? defaultIndex ?? index]

    if (!focusedHeaderItem) {
      return null
    }

    highlightTile(currentIndex)

    let HeaderComponent = getHeaderComponent(focusedHeaderItem.__typename)

    if (
      ["ats_videos", "ats_courses", "ats_bundles"].includes(
        focusedHeaderItem.__typename
      )
    ) {
      HeaderComponent = withOwnershipCheck(HeaderComponent)
    }

    return <HeaderComponent data={focusedHeaderItem} showAnchor={false} />
  }, [currentIndex, dataSource])

  const scrollBackward = () => {
    const transitionAnimate = document.querySelectorAll(".transition-animate")
    transitionAnimate.item(0).classList.add("opacity-0")
    setTimeout(() => {
      transitionAnimate.item(0).classList.remove("opacity-0")
      setCurrentIndex(Math.max(0, currentIndex - 1))
    }, 700)
  }

  const scrollForward = () => {
    const transitionAnimate = document.querySelectorAll(".transition-animate")
    transitionAnimate.item(0).classList.add("opacity-0")
    setTimeout(() => {
      transitionAnimate.item(0).classList.remove("opacity-0")
      setCurrentIndex(Math.min(dataSource.length - 1, currentIndex + 1))
    }, 700)
  }

  return (
    <div className={`hero-header ${className}`}>
      <div className="transition-animate">{focusedHeader}</div>
      {dataSource.length > 1 && (
        <div className="controls">
          <Button
            type="ghost"
            shape="circle"
            className="slide-button prev"
            icon={<LeftOutlined />}
            onClick={scrollBackward}
          />
          <Button
            type="ghost"
            shape="circle"
            className="slide-button next"
            icon={<RightOutlined />}
            onClick={scrollForward}
          />
        </div>
      )}
      {showSlider && (
        <Row className="hero-slider">
          <AtsCarousel
            colSettings={{ xs: 24, sm: 12, md: 7, lg: 5, xl: 5, xxl: 4 }}
            title={t("label:highlighted")}
            dataSource={dataSource}
            renderItem={(item, index) => {
              const TileComponent = withTileBadgeCheck(
                getTileComponent(item.__typename)
              )
              return (
                <TileComponent
                  type={"compact"}
                  data={item}
                  onClick={evt => {
                    const transitionAnimate = document.querySelectorAll(
                      ".transition-animate"
                    )
                    transitionAnimate.item(0).classList.add("opacity-0")
                    setTimeout(() => {
                      transitionAnimate.item(0).classList.remove("opacity-0")
                      setCurrentIndex(index)
                      onChange && onChange({ item, index })
                    }, 700)
                  }}
                />
              )
            }}
          />
        </Row>
      )}
    </div>
  )
}

HeroHeader.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  defaultIndex: PropTypes.number,
  index: PropTypes.number,
  onChange: PropTypes.func,
}

HeroHeader.defaultProps = {
  dataSource: [],
  defaultIndex: 0,
  index: 0,
}

export default HeroHeader
