import React, { useMemo } from "react"
import PageRoot from "../../components/PageRoot"
import { useTranslation } from "react-i18next"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { Col } from "antd"
import { graphql } from "gatsby"
import { renderCarouselItem, translatePath } from "../../utils"
import AtsCarousel from "../../components/AtsCarousel"
import HeroHeader from "../../components/HeroHeader"
import { PATH } from "../../enums/ItemTypes"
import { MASTER } from "../../enums/CertificationTypes"
import AtsGrid from "../../components/AtsGrid"

const OnDemandPage = ({ data }) => {
  const { t } = useTranslation()

  const { courses, bundles } = data.ats
  const onDemandMasters = bundles.filter(
    bundle => bundle.course_type === MASTER
  )

  const onDemandPrograms = bundles.filter(bundle => bundle.course_type === PATH)
  const mostRecentMaster = [onDemandMasters[0]]

  const heroSlider = useMemo(() => {
    if (!mostRecentMaster) return null
    return (
      <HeroHeader
        className={"on-demand-hero-header"}
        dataSource={mostRecentMaster}
        showSlider={false}
      />
    )
  }, [bundles])
  const onDemandProgramsRow = useMemo(() => {
    if (!Boolean(onDemandPrograms.length)) return null
    return (
      <AtsCarousel
        isVertical={true}
        title={t("label:onDemandPrograms")}
        url={translatePath("/on-demand/programs")}
        dataSource={onDemandPrograms}
        renderItem={renderCarouselItem}
        gutter={[32, 32]}
        colSettings={{
          xs: 24,
          md: 12,
          lg: 8,
        }}
      />
    )
  }, [bundles])

  const onDemandMastersRow = useMemo(() => {
    if (!Boolean(onDemandMasters.length)) return null
    return (
      <AtsCarousel
        isVertical={true}
        title={t("label:onDemandMasters")}
        url={translatePath("/on-demand/masters")}
        dataSource={onDemandMasters}
        renderItem={renderCarouselItem}
        gutter={[32, 32]}
        colSettings={{
          xs: 24,
          md: 12,
          lg: 8,
        }}
      />
    )
  }, [bundles])

  const onDemandCoursesRow = useMemo(() => {
    if (!Boolean(courses.length)) return null
    return (
      <AtsGrid
        isVertical={true}
        title={t("label:onDemandCourses")}
        url={translatePath("/on-demand/courses")}
        dataSource={courses}
        renderItem={renderCarouselItem}
        gutter={[32, 32]}
        colSettings={{
          xs: 24,
          md: 12,
          lg: 8,
          xl: 6,
          xxl: 4,
        }}
      />
    )
  }, [courses])

  return (
    <PageRoot title={t("label:onDemand")}>
      {heroSlider}
      <ContainerWithPadding size="full" style={{ paddingTop: 0 }}>
        <Col span={24}>
          <div className={"on-demand-masters-grid"}>{onDemandMastersRow}</div>
        </Col>
        <Col span={24}>
          <div className={"on-demand-programs-grid"}>{onDemandProgramsRow}</div>
        </Col>
        <Col span={24}>
          <div className={"on-demand-courses-grid"}>{onDemandCoursesRow}</div>
        </Col>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query onDemandQuery {
    ats {
      courses(
        order_by: { created_at: desc }
        where: { state: { _eq: PUBLISHED }, price_policy: { _eq: ON_DEMAND } }
      ) {
        seo {
          slug
        }
        ...PreviewCardCourse
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
      }
      bundles(
        order_by: { created_at: desc }
        where: {
          _and: {
            state: { _eq: "PUBLISHED" }
            course_type: { _in: ["PATH", "MASTER"] }
          }
        }
      ) {
        course_type
        description
        seo {
          slug
        }
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        ...PreviewCardBundle
      }
    }
  }
`

export default OnDemandPage
